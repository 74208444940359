@import '../../style/varibale';

.app_service_master {
    .carousel-inner {
        height: 100%;
    }
}

.app__services {
    height: calc(100vh / 1.5);
    position: relative;

    .app__slider {
        height: 100% !important;
        overflow: hidden;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #0E0E0E, $alpha: 0.4);
        display: flex;
        align-items: start;
        @media screen and (width < 767px) {
            align-items: start;
            justify-content: center;
        }

        h1 {
            font-size: 100px;
            margin-top: 200px;
            font-weight: 700;
            line-height: 123px;
            letter-spacing: 0em;
            text-align: left;
            @media screen and (width < 767px) {
                font-size:40px;
                font-weight: 600;
                width: 100%;
                text-align: center;
            }
    
        }


    }
}

.app_service_master {
    .overlay_about {
        z-index: -1 !important;
    }
}

.contact_btn{
    @media screen and (width < 767px) {
    display: flex;
    justify-content: center;
    align-items: center;
    }

}