@import '../../style/varibale';


.app_about_master {
    height: auto;
    background-image: url('../../assets/Images/bgAbout.png');
    position: relative;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 99;

    .app_about_master_container {
        width: 85%;
        margin: auto;

        @media screen and (width < 767px) {
            h1 {
                font-size: 70px;
            }
        }

    }

    .overlay {
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background-color: rgba($color: #0E0E0E, $alpha: 0.6);
        background-color: rgba($color: #000, $alpha: 0.7);

        /* Adjust the opacity as needed */
        transition: background-color 1s ease, opacity 1ms ease, display 1ms ease;


        .p-button {
            display: none;
        }

    }

    .contact {
        z-index: 1 !important;
        padding-top: 170px;
        padding-bottom: 100px;

        .about1 {

            @media screen and (width < 767px) {
                position: relative;

                .logo {
                    position: absolute;
                    left: 0;

                    img {
                        opacity: 0.7;
                    }
                }
            }

            .content {
                @media screen and (width < 767px) {
                    margin-left: 1px !important;
                    z-index: 999 !important;
                }
            }

            h3 {
                font-size: 48px;
                font-weight: 800;

                @media screen and (width < 767px) {
                    font-size: 33px;
                    font-weight: 600;
                    margin-top: 50px;
                    color: #30D7FC;
                    padding-bottom: 5px;

                }
            }

            p {
                font-size: 20px;
                font-weight: 300;
            }
        }
    }

}

[dir="ltr"] .content_ml {
    margin-left: -200px;


}


[dir="rtl"] .content_ml {
    margin-right: -200px;
    z-index: 22;

}



[dir="ltr"] .content_ml_1 {
    margin-left: -100px;

}


[dir="rtl"] .content_ml_1 {
    margin-right: -100px;
    z-index: 22;
}

[dir="rtl"] .contact .about1 .logo {

    transform: rotate(-180deg);
    z-index: 0;

    @media screen and (width < 767px) {
        position: relative;

        .logo {
            position: absolute;
            right: 0;

            img {
                opacity: 0.7;
            }
        }
    }



}

@media screen and (width < 767px) {

    // [dir="rtl"] .content_ml_1,
    [dir="rtl"] .content_ml {
        margin-right: 1px !important;
        z-index: 999 !important;
    }
}

@media screen and (width < 767px) {

    // [dir="rtl"] .content_ml_1,
    [dir="rtl"] .content_ml {
        margin-right: -300px !important;
        z-index: 999 !important;
    }
}