@import '../../../style/varibale';

.app_clients_home {
    height: auto;
    background-image: url('../../../assets/Images/bgAbout.png');
    position: relative;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;


    position: relative;

    .overlay {


        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000, $alpha: 0.7);

        /* Adjust the opacity as needed */
        transition: background-color 1s ease, opacity 1ms ease, display 1ms ease;


        .p-button {
            display: none;
        }

    }

    .app_clients_home_container {
        width: 80%;
        margin: auto;
        position: sticky;
        z-index: 99 !important;
        padding-top: 100px;
    }

    .client_span {
        color: #FFF;
        font-family: $fontBase !important;
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-transform: capitalize;

        @media screen and (width < 767px) {
            font-size: 40px;
            font-weight: 800;

        }
    }

    .p-button {
        display: flex;
        gap: 10px;
        background-color: $dark;
        border: none;

        .p-button-label {
            color: #FFF;
            font-family: $fontBase;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
        }
    }

    textarea,
    input {
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.35);
        background: #FFF;
        padding: 10px;
    }
}

.label-style {
    color: #FFF;
}

.input-style {
    background-color: transparent !important;
    color: #FFF !important;
    border: none !important;
    border-bottom: 1px solid #fff !important;
    border-radius: 0 !important;
    margin-top: 20px;
}

input::placeholder,
textarea::placeholder {

    opacity: 0.5;
    color: #FFFFFF !important;
}

.app_clients_home {
    .content2 {
        width: 100%;

        strong,
        span {
            font-size: 35px;
            font-weight: 800;
            line-height: 43px;
            @media screen and (width < 767px) {
                font-size: 40px;
                font-weight: 600;
                line-height: 43px;
                margin-top: 0px !important;
            }

        }

        strong {
            color: #30D7FC;

        }

        p { 
            font-size: 40px;
            font-weight: 300;
            line-height: 46px;
            letter-spacing: 0em;
             @media screen and (width < 900px) {
                display: none;
             }

        }
    }
}