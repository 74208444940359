@import '../../../style/varibale';

.app__about_home {
    position: relative;
    height: 100vh;
    // background-image: url('../../../assets/Images/about1.png');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 99;

    .overlay,
    .overlay3,
    .overlay2 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #0E0E0E, $alpha: 0.6);

        /* Adjust the opacity as needed */
        transition: background-color 1s ease, opacity 1ms ease, display 1ms ease;

        .span_title {
            font-size: 46px;
            font-weight: 800;
            line-height: normal;
            width: 100%; 

            @media screen and (width < 767px) {
                font-size: 50px;
                font-weight: 600;
                line-height: 12px;

            }
        }

        p {
            font-size: 30px;
            font-weight: 300;
            line-height: 46px;
            color: #fff; 

            @media screen and (width < 767px) {
                font-size: 30px;

            }
        }

        .p-button {
            display: none;
        }

        &:hover {
            background-color: transparent;

            .p-button,
            p {
                display: block;
                opacity: 1;

            }
        }
    }


}

.p-button {
    border-radius: 6px;
    background-color: transparent;
    border: 1px solid #fff;


    span {
        font-size: 20px;
        font-weight: 300;
        line-height: 23px;



    }
}

.app__about_home2 {
    background-image: url('../../../assets/Images/about2.png');
}

.app__about_home3 {
    background-image: url('../../../assets/Images/about3.png');
}