@import '../../../style/varibale';

.app__clients {
    padding-top: 50px;
    width: 85%;
    margin: auto;

    h1 {
        color: $mainColor;
        font-size: 40px;
        font-weight: 600;

        @media screen and (width < 767px) {
            font-size: 25px;

        }
    }

    .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
        transition: all 0.5s;
        background-color: $secondColor;
        width: 2rem;
    }
    .p-carousel-prev,
    .p-carousel-next {
        border-radius: 50%;
        background-color: #303030;
    }

    .p-carousel .p-carousel-indicators .p-carousel-indicator button {
        height: 5px;
        width: 1rem;
        border-radius: 5px;
    }

}

.btn_view {
    .btn_view2 {
        color: #000 !important;
        font-size: 20px;
        font-weight: 800;
        line-height: 15px;
        position: relative;
        text-decoration: none !important;

        &::after {
            content: '';
            position: absolute;
            bottom: 2px;
            width: 0%;
            height: 3px;
            border-radius: 5px;
            background-color: $mainColor;
            left: 0;
        }

        &:hover {
            &::after {
                transition: all 0.5s;
                content: '';
                position: absolute;
                bottom: 2px;
                width: 100%;
                height: 1px;
                background-color: $mainColor;

            }
        }




    }
}