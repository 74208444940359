@import '../../style/varibale';

.app__project_master {
    min-height: 100vh;
    background-image: url('../../assets/Images/bgAbout.png');
    position: relative;
    background-position: center center;
    background-size: cover;
    z-index: 99;
    padding-bottom: 100px;
    background-repeat: no-repeat;

    .overlay {
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background-color: rgba($color: #0E0E0E, $alpha: 0.6);
        background-color: rgba($color: #000, $alpha: 0.8) !important;
        /* Adjust the opacity as needed */
        transition: background-color 1s ease, opacity 1ms ease, display 1ms ease;

        .p-button {
            display: none;
        }
    }

    .app__Our_project {
        z-index: 99;

        .header {
            padding-top: 180px;

            h1 {
                font-size: 50px;
                color: $light !important;
                font-weight: 600;
                font-family: 'Roboto', sans-serif;
                position: relative;
                width: fit-content;
                z-index: 1;
                color: $mainColor;
                margin-bottom: 20px;

                @media screen and (width <900px) {
                    width: 95%;
                    font-size: 23px;
                }
            }
        }

        p {
            font-size: 30px;
            width: 85%;

            @media screen and (width <900px) {
                width: 95%;
                font-size: 16px;
            }
        }

        nav {
            display: flex;
            gap: 40px;

            strong,
            span {
                font-size: 30px;
                line-height: 28px;
                letter-spacing: 0em;


                @media screen and (width <900px) {
                    font-size: 19px;
                    text-align: center;
                }
            }

            @media screen and (width <900px) {
                gap: 26px;
                justify-content: center;
                align-items: center;
            }
        }
    }

}

[dir="ltr"] .projects_detals .project_content {
    padding-left: 100px;
}
[dir="rtl"] .projects_detals .project_content {
    padding-right: 100px;
}

.projects_detals {
    margin-top: 100px;

    img {
        border-radius: 15px;
    }

    .project_content {

        @media screen and (width <900px) {
            padding: 20px;
        }

        span {
            background-color: transparent;
            color: #000;
            font-family: Cairo, sans-serif;
            font-size: 50px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            line-height: 60px;
            text-shadow: 1px 1px 0 $light, -1px -1px 0 $light, 1px -1px 0 $light, -1px 1px 0 $secondColor;
            text-transform: capitalize;
            margin-top: 10px;

            @media screen and (width <900px) {
                padding: 20px;
            }


        }

        h2 {

            font-size: 48px;
            font-weight: 800;
            line-height: 59px;
            letter-spacing: 0em;
             
            margin-top: 20px;

            @media screen and (width <900px) {
                padding: 0 20px;
                font-size: 38px;
            }

        }

        p {

            font-size: 32px;
            font-weight: 300;
            letter-spacing: 0em;
             

            @media screen and (width <900px) {
                padding: 0 20px;
                font-size: 16px;
            }
        }
    }


}

.image_project {
    position: relative;

    .overlay {
        inset: 0;
        position: absolute;
        background-color: rgba($color: #303030, $alpha: 68%) !important;
        border-radius: 15px;
        z-index: 9;
        visibility: hidden;
    }

    &:hover {
        .overlay {
            visibility: visible;
        }
    }
}

.bg_scroll {
    overflow-x: auto;
    /* Use auto to allow scrolling when necessary */
    white-space: nowrap;
    /* Keep the items in a single line */
    -webkit-overflow-scrolling: touch;
    /* Enable smooth scrolling on iOS devices */
}

/* Add a media query for mobile devices */
@media screen and (max-width: 767px) {
    .bg_scroll {
        display: flex;
        /* Use flexbox to make items appear side by side */
        flex-wrap: nowrap;
        /* Ensure items stay in a single line */
    }

    .bg_scroll>span {
        flex: 0 0 auto;
        /* Allow items to shrink if necessary */
    }
}