@import '../../style/varibale';

.app__client {
    height: auto;
    background-image: url('../../assets/Images/bgAbout.png');
    position: relative;
    background-position: center center;
    background-size: cover;
    z-index: 99;
    padding-bottom: 100px;
    background-repeat: no-repeat;

    .overlay {
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background-color: rgba($color: #0E0E0E, $alpha: 0.6);
        background-color: rgba($color: #000, $alpha: 0.7) !important;

        /* Adjust the opacity as needed */
        transition: background-color 1s ease, opacity 1ms ease, display 1ms ease;


        .p-button {
            display: none;
        }

    }

}

.app__clients_title {

    z-index: 99;

    .header {
        margin-top: 100px;
        width: 100% !important;

        h3 {
            font-size: 50px;
            color: $light !important;
            font-weight: 600;
            font-family: 'Roboto', sans-serif;
        
            width: fit-content;
            z-index: 1;
            color: $mainColor;
            margin-bottom: 20px;

            @media screen and (width < 767px) {

                width: 100% !important; 

                text-align: center !important;
            }


        }
    }

    p {
        font-size: 30px;

        @media screen and (width < 767px) {
            font-size: 20px;
            font-weight: 600;
            width: 100%;
            text-align: center;
            width: 100% !important; 

        }

    }
}