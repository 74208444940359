@import '../../../style/varibale';

.slider_overlay {
    position: relative;

    @media screen and (width < 900px) {
        margin-top: 80px;
    }

    .overlay {
        position: absolute;
        inset: 0;
        background-color: #0000008C;

        .content {
            width: 900px !important;

            svg {
                width: 100% !important;
            }
        }
    }
}



.slick-dots li button {
    color: $secondColor !important;
}

.slick-dots li button:before {
    color: $secondColor !important;
    font-size: 10px !important;
}

.app__slider-home {

    .carousel-control-prev,
    .carousel-control-next {
        display: none !important;
    }

    .p-button {
        padding: 10px 16px;

        span {
            font-size: 20px;
        }

        @media screen and (width < 900px) {
            padding: 10px 14px;

            span {
                font-size: 16px;
            }
        }
    }

}

.carousel-indicators {
    .active {

        background-color: $secondColor !important;
    }
}

.app__silder-home {
    overflow-y: hidden;
    height: 100vh;

    .slick-dots {
        bottom: 15px !important;
    }

}

.slider__home {
    height: 100vh;
    object-fit: cover;
    object-position: center;
    z-index: -5 !important;
}

.app__slider-home {
    position: relative !important;

    h2 {
        @media screen and (width < 900px) {
            font-size: 35px !important;

        }
    }

    p {
        @media screen and (width < 900px) {

            font-size: 16px !important;
        }
    }

}

.content__silder {
    position: absolute;
    // z-index: 6999999999999999999999999999 !important;
    inset: 0 !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100% !important;
    // background-color: rgba($color: #000000, $alpha: 0.4) !important;

    .content {
        width: fit-content;
        padding: 15px 50px;
        text-align: left;
        border-radius: 0px 10px 10px 0px;
        backdrop-filter: blur(6px);
        -webkit-backdrop-filter: blur(6px);
        // background-color: rgba($color: #000000, $alpha: 0.4) !important;
        border-left: 5px solid #FA6409;

        @media screen and (max-width:464px) {
            padding: 15px;
        }

        span {

            color: #FFF;
            text-align: right;
            font-family: Cairo;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;

            @media screen and (max-width:767px) {
                font-size: 35px !important;
            }

            @media screen and (max-width:464px) {
                font-size: 25px !important;
            }
        }

        p {
            color: #FFF;
            text-align: right;
            font-family: Cairo;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;

            @media screen and (max-width:767px) {
                // font-weight: 600;
                line-height: normal;
                margin-bottom: 30px;
                margin-top: 15px;
                font-size: 20px !important;
            }
        }

        /* CSS */
        .button-86 {

            all: unset;
            width: 100px;
            height: 30px;
            font-size: 16px;
            background: transparent;
            border: none;
            position: relative;
            color: #f0f0f0;
            cursor: pointer;
            z-index: 1;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            user-select: none;
            -webkit-user-select: none;
            touch-action: manipulation;

        }

        .button-86::after,
        .button-86::before {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: -99999;
            transition: all .4s;
        }

        .button-86::before {
            transform: translate(0%, 0%);
            width: 100%;
            height: 100%;
            background: $mainColor;
            border-radius: 5px;
        }

        .button-86::after {
            transform: translate(10px, 10px);
            width: 35px;
            height: 35px;
            background: #ffffff15;
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            border-radius: 50px;
        }

        .button-86:hover::before {
            transform: translate(5%, 20%);
            width: 110%;
            height: 110%;
        }

        .button-86:hover::after {
            border-radius: 5px;
            transform: translate(0, 0);
            width: 100%;
            height: 100%;
        }

        .button-86:active::after {
            transition: 0s;
            transform: translate(0, 5%);
        }
    }
}

.content__silder-rtl {

    display: flex;
    justify-content: center;
    flex-direction: column;

    .content {


        margin-left: auto !important;
        display: flex;
        justify-content: start;
        flex-direction: column;
        text-align: right;
        width: fit-content;
        padding: 15px 50px;

        span {
            text-align: right;
        }

        p {
            text-align: right;
        }

        .link__home {
            width: fit-content !important;
        }

        /* CSS */
        .button-86 {
            text-align: right;
            all: unset;
            width: 100px;
            height: 30px;
            font-size: 16px;
            background: transparent;
            border: none;
            position: relative;
            color: #f0f0f0;
            cursor: pointer;
            z-index: 1;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            user-select: none;
            -webkit-user-select: none;
            touch-action: manipulation;

        }

        .button-86::after,
        .button-86::before {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: -99999;
            transition: all .4s;
        }

        .button-86::before {
            transform: translate(0%, 0%);
            width: 100%;
            height: 100%;
            background: $mainColor;
            border-radius: 10px;
        }

        .button-86::after {
            transform: translate(10px, 10px);
            width: 35px;
            height: 35px;
            background: #ffffff15;
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            border-radius: 50px;
        }

        .button-86:hover::before {
            transform: translate(5%, 20%);
            width: 110%;
            height: 110%;
        }

        .button-86:hover::after {
            border-radius: 10px;
            transform: translate(0, 0);
            width: 100%;
            height: 100%;
        }

        .button-86:active::after {
            transition: 0s;
            transform: translate(0, 5%);
        }
    }

}


.slider_about {
    padding-top: 80px;
    padding-bottom: 80px;


    p {
        font-size: 32px;
        font-weight: 300;
        line-height: 37px;
        letter-spacing: 0em;
        text-align: left;

    }

    h2 {
        font-size: 64px;
        font-weight: 700;
        line-height: 78px;
        letter-spacing: 0em;
        text-align: left;

    }

    .overlay_master {
        bottom: -70px;
        z-index: 0 !important;

    }

    .overlay_about {
        position: absolute;
        bottom: -90px;
        z-index: -1 !important;

        @media screen and (max-width:767px) {
            width: 70%;
        }
    }

    .overlay_about1 {
        @media screen and (max-width:767px) {
            width: 70% !important;
        }
    }

    .carousel-indicators {
        bottom: -74px !important;

        @media screen and (max-width:767px) {
            display: none;
        }
    }

    .carousel-inner {
        overflow: visible !important;
    }
}

.padding_left {
    padding-left: 98px !important;

    p,
    h2 {
        text-align: left;
    }

    @media screen and (width < 900px) {
        padding: 20px !important;
        padding-top: 0 !important;
    }
}

.padding_right {
    padding-right: 98px !important;

    p,
    h2 {
        text-align: right;
    }

    @media screen and (width < 900px) {
        padding: 20px !important;
        padding-top: 0 !important;
    }
}